import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
/*
 * Selects a subset of items from array and returns it as a new array.
 *    -replacement for angularJS "filter" filter
 * Takes a comparison prop to filter the array against and a boolean flag to indicate whether the filter should filter strictly
 * Usage:
 *   array | filter : prop : strict
 * Example:
 *   {{ [{id: 'foo'}, {id: 'bar', other: 'baz'}] | filter:'other'}}
 *   returns {id: 'bar', other: 'baz'}
 */
@Pipe({
   name: "sliceArray",
   standalone: true,
})
/**
 * @deprecated
 */
export class SliceArray implements PipeTransform {
   public transform<T = any>(arr: Array<T>, start: number, end: number): Array<T> {
      if (!Array.isArray(arr)) {
         return arr;
      }
      return arr.slice(start, end);
   }
}
